/** @jsx jsx */
import { CDNImage } from "@boxoffice/screenplay";
import { graphql } from "gatsby";
import React, { memo } from "react";
import Link from "shared/components/Link";
import { getPresentationHeight } from "shared/helpers/image";
import { jsx } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetImage" }>;
}

const ImageWidget: React.FC<Props> = ({
  isFirst,
  isLast,
  isNested,
  widget,
  prevWidget,
  nextWidget,
  containerSize,
}) => {
  const shape = widget.imageShape;
  const probe = shape?.probe;
  const useOriginalDimensions = shape?.width === "original";

  return shape?.url ? (
    <WidgetWrapper
      isFirst={isFirst}
      isLast={isLast}
      isNested={isNested}
      containerSize={containerSize}
      widget={widget}
      prevWidget={prevWidget}
      nextWidget={nextWidget}
    >
      <div sx={{ textAlign: "center", lineHeight: 0 }}>
        <Link
          to={shape?.linkTo || undefined}
          title={shape?.alt || undefined}
          sx={{
            display: "inline-block",
            margin: "0 auto",
            width: useOriginalDimensions ? undefined : "100%",
          }}
        >
          <CDNImage
            src={shape.url}
            alt={shape?.alt || ""}
            width={useOriginalDimensions ? probe?.width : 1920}
            height={
              useOriginalDimensions
                ? probe?.height
                : getPresentationHeight(1920, probe?.ratio)
            }
            sx={
              useOriginalDimensions ? { margin: "0 auto" } : { width: "100%" }
            }
          />
        </Link>
      </div>
    </WidgetWrapper>
  ) : null;
};

export const query = graphql`
  fragment WidgetImage on WidgetImage {
    id
    __typename
    imageShape {
      alt
      linkTo
      width
      url
      probe {
        width
        height
        ratio
      }
    }
  }
`;

export default memo(ImageWidget);
